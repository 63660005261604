<!--
// nuxt-ui/components/region-stats/cards-featured-stats/CardBestGoalkeeper.vue
-->
<script setup lang="ts">
import type { RankingsData } from "~/src/player/domain/Player";
import { useImages } from "~/nuxt-ui/Shared/composables/images";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { setAliasUrl } from "~/src/Shared/utils";
import { initDropdowns } from "flowbite";
import { useSeasons } from "~/nuxt-ui/Shared/composables/season/seasons";
import InfoIcon from "~/nuxt-ui/components/Shared/icons/InfoIcon.vue";

type Props = {
  seasonId: number | string;
  pageStatsLink: string;
  disclaimer?: string;
  isStats?: boolean; // Usage is in /stats
};

const props = defineProps<Props>();

const localePath = useLocalePath();
const route = useRoute();
const { regionParam, isRegionHome, isSpain, isWorldCup } = useRegions();
const { playerPlaceholderPath, videoBgFeaturedStatsPath, posterBgFeaturedStatsPath } = useImages();
const { isKings } = useWebContext();
const {
  useFetchParamsSeasonStatsFirstGoalkeeperKWC,
  useFetchParamsSeasonStatsFirstGoalkeeper,
  useFetchParamsSeasonStatsFirstPlayer,
} = useSeasons();

const queries = computed(() => {
  return route.query ? route.query : undefined;
});

const videoBg = ref<HTMLVideoElement | null>(null);

const playVideoBg = () => {
  if (videoBg.value && "play" in videoBg.value) {
    videoBg.value.play();
  }
};

const pauseVideoBg = () => {
  if (videoBg.value && "pause" in videoBg.value) {
    videoBg.value.pause();
  }
};

const bestGoalkeeper = ref<RankingsData>();
const showSaves = ref<boolean>(false);

if (isWorldCup) {
  //ratio for gk in playoffs
  const fetchOptsGoalkeepersKWC = props.seasonId
    ? useFetchParamsSeasonStatsFirstGoalkeeperKWC(Number(props.seasonId))
    : undefined;

  const { path: pathGkKwc, opts: optsGkKwc } = fetchOptsGoalkeepersKWC;
  const { data } = await useFetch(pathGkKwc, optsGkKwc);
  bestGoalkeeper.value = data.value;
} else if (!isWorldCup) {
  //show saves if it's old splits
  if (Number(props.seasonId) < 15) {
    const fetchOptsGoalkeeperSaves = props.seasonId
      ? useFetchParamsSeasonStatsFirstPlayer(Number(props.seasonId), "PRT")
      : undefined;
    const { path: pathSv, opts: optsSv } = fetchOptsGoalkeeperSaves;
    const { data } = await useFetch(pathSv, optsSv);
    showSaves.value = true;
    bestGoalkeeper.value = data.value;
  } else {
    //show saves by default in any other case
    const fetchOptsGoalkeeperSaves = props.seasonId
      ? useFetchParamsSeasonStatsFirstPlayer(Number(props.seasonId), "PRT")
      : undefined;

    const { path: pathSv, opts: optsSv } = fetchOptsGoalkeeperSaves;
    const { data } = await useFetch(pathSv, optsSv);
    showSaves.value = true;
    bestGoalkeeper.value = data.value;

    //check if the saves gk has more than or 8 played, and change to ratio gc/pm
    if (bestGoalkeeper.value?.matchesPlayed && bestGoalkeeper.value.matchesPlayed >= 8) {
      const fetchOptsGoalkeeperRatio = props.seasonId
        ? useFetchParamsSeasonStatsFirstGoalkeeper(Number(props.seasonId))
        : undefined;

      const { path: pathGk, opts: optsGk } = fetchOptsGoalkeeperRatio;
      const { data } = await useFetch(pathGk, optsGk);
      showSaves.value = false;
      bestGoalkeeper.value = data.value;
    }
  }
}

const disclaimerId = useId() || "disclaimer-dd";
const disclaimerBtnId = useId() || "disclaimer-btn-dd";

onMounted(() => {
  initDropdowns();
});
</script>

<template>
  <div
    v-if="!!bestGoalkeeper"
    class="flex flex-col max-w-[343px] w-full min-w-full lg:max-w-none 2xl:w-auto 2xl:aspect-[325/568]"
  >
    <div class="card-player-image-container">
      <nuxt-link
        :to="
          localePath({
            name: `region-stats-${pageStatsLink}`,
            params: { region: regionParam },
            query: queries,
          })
        "
        @mouseover="playVideoBg"
        @mouseout="pauseVideoBg"
        :no-prefetch="!!isRegionHome"
      >
        <video
          :src="videoBgFeaturedStatsPath"
          :poster="posterBgFeaturedStatsPath"
          class="absolute top-0 left-0 right-0 bottom-0 min-h-[450px] object-cover object-top"
          ref="videoBg"
          width="100%"
          height="100%"
          loop
          muted
        ></video>
        <div class="image-container">
          <NuxtImg
            :src="
              bestGoalkeeper?.player?.image?.url
                ? setAliasUrl(bestGoalkeeper?.player?.image?.url)
                : playerPlaceholderPath
            "
            :alt="bestGoalkeeper?.player?.shortName || ''"
            class="object-top object-cover min-h-[450px]"
            width="456"
            height="642"
            sizes="256px sm:472px md:325px"
          />
        </div>
        <div class="gradient-container">
          <div>
            <div v-if="!showSaves" class="pre-title-stat">{{ $t("cards.stats.pretitle.best") }}</div>
            <div class="title-stat">
              {{ showSaves ? $t("cards.stats.title.saves") : $t("cards.stats.title.goalkeeper") }}
            </div>
          </div>
        </div>
      </nuxt-link>
      <button
        :id="disclaimerBtnId"
        v-if="disclaimer"
        class="disclaimer"
        :data-dropdown-toggle="disclaimerId"
        data-dropdown-placement="right"
      >
        <info-icon height="25" width="25"></info-icon>
      </button>
      <div
        :id="disclaimerId"
        class="z-10 hidden bg-gray-700 rounded-lg shadow w-[200px] text-sm p-2"
        :aria-labelledby="disclaimerBtnId"
      >
        {{ disclaimer }}
      </div>
    </div>

    <div class="mt-4 table-first-stats">
      <div class="flex items-center justify-end text-gray-400 text-[10px] uppercase pl-2 pr-1">
        <span class="w-4/6 text-left">{{ $t("cards.stats.name.text") }}</span>
        <span class="w-1/6 text-center ml-auto">{{ $t("cards.stats.team.text") }}</span>
        <span class="w-1/6 text-center">{{ showSaves ? $t("Par.") : "Ratio" }}</span>
      </div>
      <div class="mt-2 flex items-center justify-end text-sm font-bold bg-gray-800 pl-2 pr-1 h-14 rounded-md">
        <component :is="isStats ? 'h2' : 'div'" class="w-4/6 text-left">
          {{ bestGoalkeeper?.player?.shortName }}
        </component>
        <div class="w-1/6 min-w-[16%] logo-team-container">
          <NuxtImg
            v-if="bestGoalkeeper?.team?.logo?.url"
            :src="setAliasUrl(bestGoalkeeper?.team?.logo?.url)"
            alt="Team"
            class="object-contain h-8 w-8 lg:w-11 lg:h-11"
            loading="lazy"
            height="44"
            width="44"
          />
        </div>
        <div class="w-1/6 flex items-center justify-center">
          {{ showSaves ? Number(bestGoalkeeper.total) : Number(bestGoalkeeper?.totalAVG) }}
        </div>
      </div>
    </div>
    <div class="text-center py-2" v-if="regionParam && pageStatsLink">
      <nuxt-link
        :to="
          localePath({
            name: `region-stats-${pageStatsLink}`,
            params: { region: regionParam },
            query: queries,
          })
        "
        class="see-more-link"
        :no-prefetch="!!isRegionHome"
        >{{ $t("cards.stats.seeMore.link") }}
      </nuxt-link>
    </div>
  </div>
</template>

<style scoped>
.card-player-image-container {
  @apply min-h-[450px] px-1 py-2 rounded-lg relative overflow-hidden bg-no-repeat bg-cover bg-center;
}

.image-container {
  @apply h-full absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-start items-center pt-5;
}

.gradient-container {
  @apply absolute min-w-full min-h-full top-0 right-0 left-0 bottom-0 bg-[linear-gradient(180deg,rgba(0,0,0,0.00)68.26%,rgba(0,0,0,0.80)100%)] px-1 py-2 flex flex-col justify-between;
}

.pre-title-stat {
  @apply text-primary-light text-center text-sm uppercase font-['Archivo_Expanded'] font-bold leading-[14px];
}

.title-stat {
  @apply text-black text-center text-2xl font-bold uppercase font-['Archivo_Expanded'] leading-none;
  -webkit-text-stroke: 1px theme("colors.primary.light");
  text-stroke: 1px theme("colors.primary.light");
}

.logo-team-container {
  @apply flex flex-col justify-center items-center ml-auto;
}

.see-more-link {
  @apply text-xs text-gray-400;
}

.disclaimer {
  @apply text-primary absolute bottom-2 left-2;
}
</style>
