<!--
// nuxt-ui/components/region-stats/cards-featured-stats/CardPlayerRankingGeneralNoPartner.vue
-->
<script setup lang="ts">
import type { PlayerRankings, RankingsData } from "~/src/player/domain/Player";
import { useImages } from "~/nuxt-ui/Shared/composables/images";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { setAliasUrl } from "~/src/Shared/utils";
import { initDropdowns } from "flowbite";

type Props = {
  seasonId: number | string;
  parameterString: string;
  pageStatsLink: string;
  preTitleStat?: string;
  titleStat: string;
  tableStatTitle: string;
  isStats?: boolean; // Usage is in /stats
  sortedRanking?: boolean;
  sortOrder?: "ASC" | "DESC";
  orderBy?: "ranking" | "rankingAVG";
};

const props = defineProps<Props>();

const localePath = useLocalePath();
const route = useRoute();
const { regionParam, isRegionHome } = useRegions();
const { playerPlaceholderPath, videoBgFeaturedStatsPath, posterBgFeaturedStatsPath } = useImages();

const queries = computed(() => {
  return route.query ? route.query : undefined;
});

const videoBg = ref<HTMLVideoElement | null>(null);

const playVideoBg = () => {
  if (videoBg.value && "play" in videoBg.value) {
    videoBg.value.play();
  }
};

const pauseVideoBg = () => {
  if (videoBg.value && "pause" in videoBg.value) {
    videoBg.value.pause();
  }
};

const { data: topPlayer }: PlayerRankings = await useFetch(
  `/api/v1/competition/seasons/${props.seasonId}/players/stats/${props.parameterString}`,
  {
    query: { page: 0, orderBy: props.orderBy, sortOrder: props.sortOrder },
    transform: input => {
      if (input && props.parameterString === "GOL-S") {
        const goalkeepers = input.filter((r: RankingsData) => r.player.role === "goalkeeper");
        return goalkeepers[0];
      } else {
        return input[0];
      }
    },
  }
);

onMounted(() => {
  initDropdowns();
});
</script>

<template>
  <div
    v-if="!!topPlayer"
    class="flex flex-col max-w-[343px] w-full min-w-full lg:max-w-none 2xl:w-auto 2xl:aspect-[325/568]"
  >
    <div class="card-player-image-container">
      <nuxt-link
        :to="
          localePath({
            name: `region-stats-${pageStatsLink}`,
            params: { region: regionParam },
            query: queries,
          })
        "
        @mouseover="playVideoBg"
        @mouseout="pauseVideoBg"
        :no-prefetch="!!isRegionHome"
      >
        <video
          :src="videoBgFeaturedStatsPath"
          :poster="posterBgFeaturedStatsPath"
          class="absolute top-0 left-0 right-0 bottom-0 min-h-[450px] object-cover object-top"
          ref="videoBg"
          width="100%"
          height="100%"
          loop
          muted
        ></video>
        <div class="image-container">
          <NuxtImg
            :src="
              topPlayer?.player?.image?.url ? setAliasUrl(topPlayer.player.image.url) : playerPlaceholderPath
            "
            :alt="topPlayer?.player?.shortName || ''"
            class="object-top object-cover min-h-[450px]"
            width="456"
            height="642"
            sizes="256px sm:472px md:325px"
          />
        </div>
        <div class="gradient-container">
          <div>
            <div class="pre-title-stat">{{ preTitleStat }}</div>
            <div class="title-stat">{{ titleStat }}</div>
          </div>
        </div>
      </nuxt-link>
    </div>

    <div class="mt-4 table-first-stats">
      <div class="flex items-center justify-end text-gray-400 text-[10px] uppercase pl-2 pr-1">
        <span class="w-4/6 text-left">{{ $t("cards.stats.name.text") }}</span>
        <span class="w-1/6 text-center ml-auto">{{ $t("cards.stats.team.text") }}</span>
        <span class="w-1/6 text-center">{{ tableStatTitle }}</span>
      </div>
      <div class="mt-2 flex items-center justify-end text-sm font-bold bg-gray-800 pl-2 pr-1 h-14 rounded-md">
        <component :is="isStats ? 'h2' : 'div'" class="w-4/6 text-left">
          {{ topPlayer?.player?.shortName }}
        </component>
        <div class="w-1/6 min-w-[16%] logo-team-container">
          <NuxtImg
            v-if="topPlayer?.team?.logo?.url"
            :src="setAliasUrl(topPlayer.team.logo.url)"
            alt="Team"
            class="object-contain h-8 w-8 lg:w-11 lg:h-11"
            loading="lazy"
            height="44"
            width="44"
          />
        </div>
        <div class="w-1/6 flex items-center justify-center">
          {{ Number(topPlayer.total) }}
        </div>
      </div>
    </div>
    <div class="text-center py-2" v-if="regionParam && pageStatsLink">
      <nuxt-link
        :to="
          localePath({
            name: `region-stats-${pageStatsLink}`,
            params: { region: regionParam },
            query: queries,
          })
        "
        class="see-more-link"
        :no-prefetch="!!isRegionHome"
        >{{ $t("cards.stats.seeMore.link") }}
      </nuxt-link>
    </div>
  </div>
</template>

<style scoped>
.card-player-image-container {
  @apply min-h-[450px] px-1 py-2 rounded-lg relative overflow-hidden bg-no-repeat bg-cover bg-center;
}

.image-container {
  @apply h-full absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-start items-center pt-5;
}

.gradient-container {
  @apply absolute min-w-full min-h-full top-0 right-0 left-0 bottom-0 bg-[linear-gradient(180deg,rgba(0,0,0,0.00)68.26%,rgba(0,0,0,0.80)100%)] px-1 py-2 flex flex-col justify-between;
}

.pre-title-stat {
  @apply text-primary-light text-center text-sm uppercase font-['Archivo_Expanded'] font-bold leading-[14px];
}

.title-stat {
  @apply text-black text-center text-2xl font-bold uppercase font-['Archivo_Expanded'] leading-none;
  -webkit-text-stroke: 1px theme("colors.primary.light");
  text-stroke: 1px theme("colors.primary.light");
}

.logo-team-container {
  @apply flex flex-col justify-center items-center ml-auto;
}

.see-more-link {
  @apply text-xs text-gray-400;
}
</style>
